import catalogStyles from '../sass/catalog.scss';
let commons = require('./utils/commons');
import customPriceSLiderJS from './includes/custom_price_slider.js';
import filterMenuJS from './includes/filter_menu.js';
import brandAlphaberChange from './includes/brand_alphabet_change.js';
import categoryPlain1IMG from '../img/category-plain-1.png';
import categoryPlain2IMG from '../img/category-plain-2.png';
import categoryPlain3IMG from '../img/category-plain-3.png';
import categoryPlain4IMG from '../img/category-plain-4.png';
import categoryPlain5IMG from '../img/category-plain-5.png';
import categoryPlain6IMG from '../img/category-plain-6.png';
import complectDetail1IMG from '../img/complect-detail-1.png';
import complectDetail2IMG from '../img/complect-detail-2.png';
import complectItem1IMG from '../img/complect-item-1.png';
import complectItem2IMG from '../img/complect-item-2.png';
import complectItem3IMG from '../img/complect-item-3.png';
import brandsFilterIMG from '../img/brands-filter.png';