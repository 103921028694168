(function( $ ) {

    $.fn.brandAlphabetChange = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            const jqContainer = $(this);
            const checkboxContaainer = jqContainer.find('input[name="language-select"]');
            checkboxContaainer.change(function() {
                if(this.checked) {
                    jqContainer.removeClass('eng');
                    jqContainer.addClass('rus');
                } else {
                    jqContainer.removeClass('rus');
                    jqContainer.addClass('eng');
                }
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.brand-alphabet-change').brandAlphabetChange();
});
