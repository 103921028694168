(function( $ ) {

    $.fn.filterMenu = function(action) {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            const jqContaienr = $(this);
            const openLink = jqContaienr.find('.group-heading')[0];
            if (!openLink) {
                console.error('filterMenu: openLink not found by query ".group-heading" in ', this);
                return false;
            }
            const subMenuContainer = jqContaienr.find('.group-body')[0];
            const subMenuContainerInner = jqContaienr.find('.group-body .group-body-holder')[0];
            if (!subMenuContainer) {
                console.error('filterMenu: subMenuContainer not found by query ".group-body" in ', this);
                return false;
            }
            // init
            if (!subMenuContainerInner) {
                console.error('filterMenu: subMenuContainerInner not found by query ".group-body .group-body-holder" in ', this);
                return false;
            }
            const openHeight = $(subMenuContainerInner).innerHeight();
            if (jqContaienr.hasClass('open')) {
                $(subMenuContainer).height(openHeight);
            } else {
                $(subMenuContainer).height(0);
            }
            // events
            $(openLink).click(function(e) {
                e.preventDefault();
                const openHeight = $(subMenuContainerInner).innerHeight();
                console.log('openHeight', openHeight)
                if (jqContaienr.hasClass('open')) {
                    jqContaienr.removeClass('open')
                    $(subMenuContainer).height(0);
                } else {
                    jqContaienr.addClass('open')
                    $(subMenuContainer).height(openHeight);
                }
            })
        });
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.filter .filter-group').filterMenu();
});
